.slider-dot {
    height: 10px;
    width: 10px;
    margin-left: 8px;
    margin-top: 8px;
    border-radius: 50%;
    background: #D9D9D9;
  }
  .slider-active-dot {
    background: #3DC6D8;
  }