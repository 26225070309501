@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Raleway:wght@400;500;700;900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #DFE6F3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p{
  font-family: 'Raleway';
  color: #fff;
  margin: 0;
  font-style: normal;
  line-height: normal;
}
button {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0px;
}
input,
button,
a,
textarea {
    font-family: 'Lexend';
    -webkit-tap-highlight-color: transparent;
}
